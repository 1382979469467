// App.js
import React from 'react';
import HomePage from './pages/homepage';
import './style.scss'
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <HomePage/>
  );
}

export default App;
